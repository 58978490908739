<template>
	<el-table
		border
		style="width: 1000px; margin-bottom: 18px;"
		header-cell-class-name="normal-table-header"
		:cell-style="{paddingLeft:'15px', fontSize: '13px', color:'#666'}"
		:header-cell-style="{paddingLeft:'15px', fontSize: '13px', color:'#000'}"
		:data="saleSummaryTable"
		:span-method="objectSpanMethod"
	>
		<template v-for="item of saleSummaryColumns">
			<el-table-column :show-overflow-tooltip="true" v-if="item.prop === 'processStep'" :key="item.prop" :label="item.label" :prop="item.prop">
				<template v-slot:default="{ row }">
					<p>{{ processTypeMap[row.processStep] }}</p>
				</template>
			</el-table-column>
			<el-table-column :show-overflow-tooltip="true" v-else-if="item.prop === 'processAction'" :key="item.prop" :label="item.label" :prop="item.prop">
				<template v-slot:default="{ row }">
					<p v-show="processSaleMap[row.processAction]">{{ processSaleMap[row.processAction] }}</p>
				</template>
			</el-table-column>
			<el-table-column :show-overflow-tooltip="true" v-else-if="item.prop === 'processResult'" :key="item.prop" :label="item.label" :prop="item.prop">
				<template v-slot:default="{ row }">
					<p v-show="processSaleMap[row.processResult]">
						<!-- 网教单品 processAction==2003没有已关闭状态-->
						<span v-if="pageData.orderOriginType === OrderOriginTypeEnum.nc && row.processAction === 2002">--</span>
						<span v-else-if="pageData.orderOriginType === OrderOriginTypeEnum.nc && row.processAction === 2003">--</span>
						<span v-else-if="pageData.goodsTemplate === goodsTemplateEnum.wjdp">{{row.processAction === 2003 ? '--' : processSaleMap[row.processResult]}}</span>
						<span v-else>{{ processSaleMap[row.processResult] }}</span>
					</p>
				</template>
			</el-table-column>
			<el-table-column
				v-else-if="item.prop === 'do'"
				:key="item.prop"
				:label="item.label"
				:prop="item.prop"
				:width="item.width"
			>
				<template v-slot:default="{ row }">
					<div v-if="pageData.orderOriginType === OrderOriginTypeEnum.nc"><el-button type="primary" class="btn" v-if="row.processAction === 2001" @click="showReviewRecord">审核记录</el-button></div>
					<div
						v-else-if="[1005].indexOf(row.processStep) != -1"
						style="color: #337AFF; cursor: pointer"
						class="seeBtn"
						@click="goDetailById(pageData, 'old')"
					>
					<el-button type="primary" plain class="btn">查看</el-button>
						
					</div>
					<div
						v-else-if="[1006, 1007, 1008].indexOf(row.processStep) != -1"
						:style="{ color: pageData.newOrder ? '#337AFF' : '#999', cursor: 'pointer' }"
						class="seeBtn"
						@click="pageData.newOrder ? goDetailById(pageData, 'new') : ''"
					>
						<el-button type="primary" plain class="btn">查看</el-button>
					</div>
					<div v-else>
						<el-button type="primary" class="btn" plain v-if="row.processAction === 2001" @click="showReviewRecord">审核记录</el-button>
					</div>
				</template>
			</el-table-column>
			<el-table-column :show-overflow-tooltip="true"
				v-else-if="item.prop === 'processLastModifyDateTime'"
				:key="item.prop"
				:label="item.label"
				:prop="item.prop"
			>
				<template v-slot:default="{ row }">
					<p v-show="row.processLastModifyDateTime">
						<span v-if="pageData.orderOriginType === OrderOriginTypeEnum.nc && (row.processAction === 2002 || row.processAction === 2003)">--</span>
						<span v-else>{{ row.processLastModifyDateTime | formatDate2('YYYY-MM-DD HH:mm:ss')  }}</span>
					</p>
				</template>
			</el-table-column>
		</template>
	</el-table>
</template>

<script>
import { goodsTemplateEnum, OrderOriginTypeEnum, processSaleMap, processTypeMap } from '@/views/order/constants'
export default {
	props:{
		pageData: Object,
		saleSummaryTable: Array,
	},
	data(){
		return {
			processSaleMap,
			goodsTemplateEnum,
			processTypeMap,
			saleSummaryColumns: [
				{ label: "售后流程", prop: "processStep" },
				{ label: "状态", prop: "processAction" },
				{ label: "处理结果", prop: "processResult" },
				{ label: "操作", prop: "do", width: "140px" },
				{ label: "时间", prop: "processLastModifyDateTime" },
			],
			spanArr: [],
			doSpanArr: [], //操作栏合并
			OrderOriginTypeEnum
		}
	},
	watch: {
		saleSummaryTable(saleSummaryTable){
			this.getSpanArr(saleSummaryTable)
		}
	},
	methods: {
		goDetailById(pageData, type){
			let order = type == "new" ? pageData.newOrder : pageData.aftersaleOrderVo[0]
			let orderId = order.orderId
			this.orderId = order.orderId || "";
			let path = `${pageData.businessId}` === '1' ?  "/afterSale/cjOrderDetail": "/afterSale/xlOrderDetail";
			this.$router.push( {
				path: path,
				query: {
					orderId: orderId,
					status: order.orderStatus
				}
			}); 
		},
		// 售后概况的合并表格
    objectSpanMethod({ row, column, rowIndex, columnIndex }) {
      // console.log('售后合并表格', this.spanArr);
      if (columnIndex === 0) {
        const _row = this.spanArr[rowIndex]
        const _col = _row > 0 ? 1 : 0
        // console.log(`合并行和列rowspan:${_row} colspan:${_col}`)
        return {
          // [0,0] 表示这一行不显示， [2,1]表示行的合并数
          rowspan: _row,
          colspan: _col,
        }
      } else if (columnIndex === 3) {
        const _row = this.doSpanArr[rowIndex]
        const _col = _row > 0 ? 1 : 0
        // console.log(`合并行和列rowspan:${_row} colspan:${_col}`)
        return {
          // [0,0] 表示这一行不显示， [2,1]表示行的合并数
          rowspan: _row,
          colspan: _col,
        }
      }
    },
		// 合并单元格
    getSpanArr(data) {
      // this.doSpanArr = [data.length]; //操作类全合并
      console.log('data合并单元格', data);
      for (var i = 0; i < data.length; i++) {
        if (i === 0) {
          this.doSpanArr.push(1)
          this.spanArr.push(1)
          this.pos = 0
        } else {
          // 判断当前元素与上一个元素是否相同
          if (data[i].processStep === data[i - 1].processStep) {
            this.spanArr[this.pos] += 1
            this.spanArr.push(0)
            this.doSpanArr[this.pos] += 1
            this.doSpanArr.push(0)
          } else {
            this.spanArr.push(1)
            this.doSpanArr.push(1)
            this.pos = i
          }
        }
      } 
    },
	// 展开审核记录
	showReviewRecord(){
		this.$emit('showReviewRecord')
	},
	}
}
</script>

<style lang="scss" scoped>
.el-button {
  &.btn{
    padding: 9px 18px;
    min-width: 92px;
  }
}
</style>