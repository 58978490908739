var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-table",
    {
      staticStyle: { width: "1000px", "margin-bottom": "18px" },
      attrs: {
        border: "",
        "header-cell-class-name": "normal-table-header",
        "cell-style": { paddingLeft: "15px", fontSize: "13px", color: "#666" },
        "header-cell-style": {
          paddingLeft: "15px",
          fontSize: "13px",
          color: "#000"
        },
        data: _vm.saleSummaryTable,
        "span-method": _vm.objectSpanMethod
      }
    },
    [
      _vm._l(_vm.saleSummaryColumns, function(item) {
        return [
          item.prop === "processStep"
            ? _c("el-table-column", {
                key: item.prop,
                attrs: {
                  "show-overflow-tooltip": true,
                  label: item.label,
                  prop: item.prop
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(ref) {
                        var row = ref.row
                        return [
                          _c("p", [
                            _vm._v(_vm._s(_vm.processTypeMap[row.processStep]))
                          ])
                        ]
                      }
                    }
                  ],
                  null,
                  true
                )
              })
            : item.prop === "processAction"
            ? _c("el-table-column", {
                key: item.prop,
                attrs: {
                  "show-overflow-tooltip": true,
                  label: item.label,
                  prop: item.prop
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(ref) {
                        var row = ref.row
                        return [
                          _c(
                            "p",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.processSaleMap[row.processAction],
                                  expression:
                                    "processSaleMap[row.processAction]"
                                }
                              ]
                            },
                            [
                              _vm._v(
                                _vm._s(_vm.processSaleMap[row.processAction])
                              )
                            ]
                          )
                        ]
                      }
                    }
                  ],
                  null,
                  true
                )
              })
            : item.prop === "processResult"
            ? _c("el-table-column", {
                key: item.prop,
                attrs: {
                  "show-overflow-tooltip": true,
                  label: item.label,
                  prop: item.prop
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(ref) {
                        var row = ref.row
                        return [
                          _c(
                            "p",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.processSaleMap[row.processResult],
                                  expression:
                                    "processSaleMap[row.processResult]"
                                }
                              ]
                            },
                            [
                              _vm.pageData.orderOriginType ===
                                _vm.OrderOriginTypeEnum.nc &&
                              row.processAction === 2002
                                ? _c("span", [_vm._v("--")])
                                : _vm.pageData.orderOriginType ===
                                    _vm.OrderOriginTypeEnum.nc &&
                                  row.processAction === 2003
                                ? _c("span", [_vm._v("--")])
                                : _vm.pageData.goodsTemplate ===
                                  _vm.goodsTemplateEnum.wjdp
                                ? _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        row.processAction === 2003
                                          ? "--"
                                          : _vm.processSaleMap[
                                              row.processResult
                                            ]
                                      )
                                    )
                                  ])
                                : _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.processSaleMap[row.processResult]
                                      )
                                    )
                                  ])
                            ]
                          )
                        ]
                      }
                    }
                  ],
                  null,
                  true
                )
              })
            : item.prop === "do"
            ? _c("el-table-column", {
                key: item.prop,
                attrs: {
                  label: item.label,
                  prop: item.prop,
                  width: item.width
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(ref) {
                        var row = ref.row
                        return [
                          _vm.pageData.orderOriginType ===
                          _vm.OrderOriginTypeEnum.nc
                            ? _c(
                                "div",
                                [
                                  row.processAction === 2001
                                    ? _c(
                                        "el-button",
                                        {
                                          staticClass: "btn",
                                          attrs: { type: "primary" },
                                          on: { click: _vm.showReviewRecord }
                                        },
                                        [_vm._v("审核记录")]
                                      )
                                    : _vm._e()
                                ],
                                1
                              )
                            : [1005].indexOf(row.processStep) != -1
                            ? _c(
                                "div",
                                {
                                  staticClass: "seeBtn",
                                  staticStyle: {
                                    color: "#337AFF",
                                    cursor: "pointer"
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.goDetailById(
                                        _vm.pageData,
                                        "old"
                                      )
                                    }
                                  }
                                },
                                [
                                  _c(
                                    "el-button",
                                    {
                                      staticClass: "btn",
                                      attrs: { type: "primary", plain: "" }
                                    },
                                    [_vm._v("查看")]
                                  )
                                ],
                                1
                              )
                            : [1006, 1007, 1008].indexOf(row.processStep) != -1
                            ? _c(
                                "div",
                                {
                                  staticClass: "seeBtn",
                                  style: {
                                    color: _vm.pageData.newOrder
                                      ? "#337AFF"
                                      : "#999",
                                    cursor: "pointer"
                                  },
                                  on: {
                                    click: function($event) {
                                      _vm.pageData.newOrder
                                        ? _vm.goDetailById(_vm.pageData, "new")
                                        : ""
                                    }
                                  }
                                },
                                [
                                  _c(
                                    "el-button",
                                    {
                                      staticClass: "btn",
                                      attrs: { type: "primary", plain: "" }
                                    },
                                    [_vm._v("查看")]
                                  )
                                ],
                                1
                              )
                            : _c(
                                "div",
                                [
                                  row.processAction === 2001
                                    ? _c(
                                        "el-button",
                                        {
                                          staticClass: "btn",
                                          attrs: { type: "primary", plain: "" },
                                          on: { click: _vm.showReviewRecord }
                                        },
                                        [_vm._v("审核记录")]
                                      )
                                    : _vm._e()
                                ],
                                1
                              )
                        ]
                      }
                    }
                  ],
                  null,
                  true
                )
              })
            : item.prop === "processLastModifyDateTime"
            ? _c("el-table-column", {
                key: item.prop,
                attrs: {
                  "show-overflow-tooltip": true,
                  label: item.label,
                  prop: item.prop
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(ref) {
                        var row = ref.row
                        return [
                          _c(
                            "p",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: row.processLastModifyDateTime,
                                  expression: "row.processLastModifyDateTime"
                                }
                              ]
                            },
                            [
                              _vm.pageData.orderOriginType ===
                                _vm.OrderOriginTypeEnum.nc &&
                              (row.processAction === 2002 ||
                                row.processAction === 2003)
                                ? _c("span", [_vm._v("--")])
                                : _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        _vm._f("formatDate2")(
                                          row.processLastModifyDateTime,
                                          "YYYY-MM-DD HH:mm:ss"
                                        )
                                      )
                                    )
                                  ])
                            ]
                          )
                        ]
                      }
                    }
                  ],
                  null,
                  true
                )
              })
            : _vm._e()
        ]
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }